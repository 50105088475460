<!-- 绩效考核 -->

<template>
	<div id="merits">
		<div>
			<el-date-picker v-model="selectMonth" type="month" placeholder="请选择时间">
			</el-date-picker>
		</div>

		<!-- 月度考核统计 -->
		<div class="table">
			<div>
				<span class="font-bold font-16">月度考核统计</span>
				<span class="text-grey font-14">（{{ monthlyDatas.starttime }} -
					{{ monthlyDatas.endtime }}）</span>
			</div>
			<CusTable ref="cusTable" title="任务管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
				:cusColums="tableInfo.columns" :ispaging="false">
			</CusTable>
		</div>

		<!-- 季度考核统计 -->
		<div class="table">
			<div class="flex justify-btw s-m-b" style="line-height: 40px;">
				<div>
					<span class="font-bold font-16">季度考核统计</span>
					<span class="text-grey font-14">（{{ quarterDatas.starttime }} -
						{{ quarterDatas.endtime }}）</span>
				</div>
				<div class="flex">
					<div class="font-14 m-m-r">
						<span class="text-grey">考评期间任务总数(A)：</span>
						<span class="text-blue">{{ quarterDatas.all_task_num }}</span>
					</div>
					<div class="font-14">
						<span class="text-grey">可用奖金：</span>
						<span class="text-blue">{{ quarterDatas.all_bonus }}</span>
					</div>
				</div>
				<div class="flex">
					<div class="font-14 m-m-r">
						<span class="font-bold">突出贡献奖励：</span>
						<span class="text-blue">{{ quarterDatas.contribute_money }}</span>
					</div>
					<el-button @click="onDetailsbtn" type="primary" plain>详情</el-button>
					<el-button type="primary" @click="onAddBtn"
						v-if="$pubFun_.verify_permiss($api_.state.Task.PerforAppraisal.addContribute.token,$menu_.state.roleNode)">新增
					</el-button>
				</div>
			</div>
			<el-table :data="quarterDatas.data" style="width: 100%" height='600' v-loading="quarterLoading">
				<el-table-column prop="u_name" label="姓名" show-overflow-tooltip />
				<el-table-column prop="ytt_title" label="所属团队" show-overflow-tooltip>
					<template #default="scope">
						<span class="text-grey">{{ scope.row.ytt_title }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="du_name" label="岗位" show-overflow-tooltip>
					<template #default="scope">
						<span class="text-grey">{{ scope.row.du_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="超期数量(D)">
					<el-table-column prop="leading_task_num" label="提前完成数量(T)" show-overflow-tooltip>
						<template #default="scope">
							<span class="text-blue">{{ scope.row.leading_task_num }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="serious_overtime_task_num" label="严重超期数量" show-overflow-tooltip>
						<template #default="scope">
							<span class="text-red">{{ scope.row.serious_overtime_task_num }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="normal_overtime_task_num" label="普通超期数量" show-overflow-tooltip>
						<template #default="scope">
							<span class="text-orange">{{ scope.row.normal_overtime_task_num }}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="score" label="考评得分(T-D)/A" show-overflow-tooltip />
				<el-table-column prop="fine_money" label="季度罚款" show-overflow-tooltip>
					<template #default="scope">
						<span class="text-blue">{{ scope.row.fine_money }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="bonus_money" label="季度奖金" show-overflow-tooltip>
					<template #default="scope">
						<span class="text-orange">{{ scope.row.bonus_money }}</span>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 年度考核统计 -->
		<div class="table">
			<div class="flex justify-btw s-m-b" style="line-height: 40px;">
				<div>
					<span class="font-bold font-16">年度考核统计</span>
					<span class="text-grey font-14">（{{ annualDatas.annualOther.starttime }} -
						{{ annualDatas.annualOther.endtime }}）</span>
				</div>
				<div class="flex">
					<div class="font-14 m-m-r">
						<span class="text-grey">考评期间任务总数(A)：</span>
						<span class="text-blue">{{ annualDatas.annualOther.all_task_num }}</span>
					</div>
					<div class="font-14">
						<span class="text-grey">可用奖金：</span>
						<span class="text-blue">{{ annualDatas.annualOther.all_bonus }}</span>
					</div>
				</div>
				<div>
				</div>
			</div>
			<CusTable ref="cusTable" title="任务管理" :datas="annualDatas.tables" :cusConf="annualDatas.conf"
				:cusColums="annualDatas.columns" :ispaging="false">
			</CusTable>
		</div>

		<!-- 新增突出贡献 -->
		<AddContribute ref="contributeRef" @determineChange="determineChange"></AddContribute>

		<!-- 详情 -->
		<AssessmentDetails ref="detailsRef" :selectMonth="selectMonth"></AssessmentDetails>
	</div>
</template>

<script type="text/javascript">
	import CusTable from '@/components/common/CusTable.vue';
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		watch, onMounted,
	} from 'vue';
	import tableColumns from '@/assets/json/task/table/monthly-assess-cloumns.json';
	import annualColumns from '@/assets/json/task/table/annual-cloumns.json';
	import AddContribute from '@/components/task/AddContribute.vue';
	import AssessmentDetails from '@/components/task/AssessmentDetails.vue';


	export default defineComponent({
		name: 'performaAppraisal',
		components: {
			CusTable,
			AddContribute,
			AssessmentDetails,
		},
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties;
			const selectMonth = ref(new Date().getFullYear() + '-' + new Date().getMonth());  // 筛选日期-默认当前日期上一个月
			const monthlyDatas = ref({ starttime: '', endtime: '', });  // 月度日期
			// 月度信息
			const tableInfo = reactive({
				list: [],
				columns: ctx.$pubFun_.verify_tableColumns(tableColumns.list, 'TaskManage'),
				conf: {
					loadStatus: false,
				}
			})
			const quarterDatas = ref({});  // 季度数据
			const quarterLoading = ref(false);  // 季度数据加载状态
			const annualDatas = reactive({  // 年度数据
				tables: [],
				columns: ctx.$pubFun_.verify_tableColumns(annualColumns.list, 'TaskManage'),
				conf: {
					loadStatus: false,
				},
				annualOther: {},
			});
			const contributeRef = ref(null);  // 新增贡献对象
			const detailsRef = ref(null);  // 详情对象

			/**
			 * 
			 * 获取月度考核数据
			 * 
			 * **/
			const getExamineMonthStatistics = () => {
				tableInfo.conf.loadStatus = true;
				ctx.$request_nl_.post(ctx.$api_.state.Task.PerforAppraisal.examineMonthStatistics.url, { month: selectMonth.value })
					.then((res) => {
						if (res.Code === 200) {
							tableInfo.list = res.Data.data ? res.Data.data : [];
							monthlyDatas.value.starttime = res.Data.starttime;
							monthlyDatas.value.endtime = res.Data.endtime;
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: res.Message,
								type: 'warning'
							})
						}
						tableInfo.conf.loadStatus = false;
					})
			}
			/**
			 * 
			 * 获取季度考核数据
			 * 
			 * **/
			const getExamineSeasonStatistics = () => {
				quarterLoading.value = true;
				ctx.$request_nl_.post(ctx.$api_.state.Task.PerforAppraisal.examineSeasonStatistics.url, { month: selectMonth.value })
					.then((res) => {
						if (res.Code === 200) {
							quarterDatas.value = res.Data;
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: res.Message,
								type: 'warning'
							})
						}
						quarterLoading.value = false;
					})
			}
			/**
			 * 
			 * 获取年度考核数据
			 * 
			 * **/
			const getExamineYearStatistics = () => {
				annualDatas.conf.loadStatus = true;
				ctx.$request_nl_.post(ctx.$api_.state.Task.PerforAppraisal.examineYearStatistics.url, { month: selectMonth.value })
					.then((res) => {
						if (res.Code === 200) {
							annualDatas.tables = res.Data.data;
							annualDatas.annualOther = res.Data;
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: res.Message,
								type: 'warning'
							})
						}
						annualDatas.conf.loadStatus = false;
					})
			}
			/**
			 * 
			 * 新增按钮
			 * 
			 * **/
			const onAddBtn = () => {
				contributeRef.value.openDialog();
			}
			/**
			 * 
			 * 新增成功后重新获取数据
			 * 
			 * **/
			const determineChange = () => {
				getExamineMonthStatistics();
				getExamineSeasonStatistics();
				getExamineYearStatistics();
			}
			/**
			 * 
			 * 详情按钮
			 * 
			 * **/
			const onDetailsbtn = () => {
				detailsRef.value.openDialog();
			}

			onMounted(() => {
				// selectMonth.value = new Date().getFullYear() + '-' + new Date().getMonth();
				getExamineMonthStatistics();
				getExamineSeasonStatistics();
				getExamineYearStatistics();
			});

			return {
				tableInfo,
				selectMonth,
				getExamineMonthStatistics,
				monthlyDatas,
				quarterDatas,
				annualDatas,
				quarterLoading,
				contributeRef,
				onAddBtn,
				determineChange,
				detailsRef,
				onDetailsbtn,
			}
		}
	})
</script>

<style lang="less">
	#merits {
		.flex {
			display: flex;
		}

		.justify-btw {
			justify-content: space-between;
		}

		.font-bold {
			font-weight: bold;
		}

		.text-grey {
			color: #acadad;
		}

		.text-blue {
			color: #409eff;
		}

		.text-red {
			color: #f56c6c;
		}

		.text-orange {
			color: #ffba00;
		}

		.font-14 {
			font-size: 14px;
		}

		.font-16 {
			font-size: 16px;
		}

		.s-m-r {
			margin-right: 10px;
		}

		.m-m-r {
			margin-right: 20px;
		}

		.s-m-b {
			margin-bottom: 10px;
		}

		.el-table__cell {
			text-align: center;
		}
	}
</style>