<!-- 突出贡献详情 -->

<template>
	<div id="assessment_details">
		<el-dialog title="突出贡献" v-model="isShowDialog" destroy-on-close :width="800">
			<CusTable ref="cusTable" title="团队管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
				:cusColums="tableInfo.columns" :ispaging="false"></CusTable>
		</el-dialog>
	</div>
</template>

<script>
	import { getCurrentInstance, defineComponent, ref, reactive, } from 'vue';
	import CusTable from '@/components/common/CusTable.vue';

	export default defineComponent({
		name: 'assessment_details',
		components: {
			CusTable
		},
		props: ['selectMonth',],
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties;
			const isShowDialog = ref(false);  // 是否显示弹框
			const tableInfo = reactive({
				list: [],
				conf: {
					loadStatus: false,
				},
				columns: [
					{
						"label": "姓名",
						"prop": "u_name",
						"show": true
					},
					{
						"label": "金额",
						"prop": "money",
						"show": true,
						"color": "#ffba00"
					},
					{
						"label": "事由",
						"prop": "desc",
						"show": true,
						"color": "#acadad"
					},
					{
						"label": "归属季度",
						"prop": "season_name",
						"show": true,
						"color": "#acadad"
					}
				],
			})
			/**
			 * 
			 * 打开弹框
			 * 
			 * **/
			const openDialog = () => {
				getContributeList();
				isShowDialog.value = true;
			}
			/**
			 * 
			 * 获取详情数据
			 * 
			 * **/
			const getContributeList = () => {
				ctx.$request_.post(ctx.$api_.state.Task.PerforAppraisal.getContributeList.url, { month: props.selectMonth })
					.then((res) => {
						if (res.Code === 200) {
							tableInfo.list = res.Data ? res.Data : [];
							if (tableInfo.list.length > 0) {
								tableInfo.list.forEach(item => {
									item.u_name = item.user ? item.user.u_name : '';
								})
							}
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: res.Message,
								type: 'warning'
							})
						}
					})
			}

			return {
				tableInfo,
				isShowDialog,
				openDialog,
				getContributeList,
			}
		}
	})
</script>

<style lang="less">
	#assessment_details {}
</style>