<!-- 新增突出贡献 -->

<template>
	<div id="add_contribute">
		<el-dialog title="新增突出贡献" v-model="isShowDialog" destroy-on-close>
			<el-form ref="formRef" :model="formDatas" label-width="120px" :rules="rules" :label-position="'top'">
				<el-row :gutter="20">
					<el-col :span="8">
						<el-form-item label="奖励员工" prop="uid">
							<el-select v-model="formDatas.uid" placeholder="请选择奖励员工" clearable>
								<el-option :label="item.u_name" :value="item.u_id" v-for="(item, i) in staffOption"
									:key="i" />
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="8">
						<el-form-item label="奖励金额" prop="money">
							<el-input v-model="formDatas.money" type="number" clearable placeholder="请输入奖励金额" />
						</el-form-item>
					</el-col>

					<el-col :span="8">
						<el-form-item label="奖励时间" prop="quarterMonth">
							<el-date-picker v-model="formDatas.quarterMonth" type="monthrange" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" format="YYYY/MM"
								value-format="YYYY-MM" />
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="奖励事由" prop="desc">
							<el-input v-model="formDatas.desc" type="textarea" clearable placeholder="请输入奖励事由"
								:rows="3" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<template #footer>
				<span class="dialog-footer">
					<el-button type="primary" @click="onConfirmAddBtn" :loading="btnLoading">确认添加</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import { ref, getCurrentInstance, defineComponent, } from 'vue';

	export default defineComponent({
		name: 'addContribute',
		components: {},
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties;
			const isShowDialog = ref(false);  // 是否显示弹框
			const staffOption = ref([]);  // 员工选项
			const formRef = ref(null);  // 表单对象
			const btnLoading = ref(null);  // 按钮加载状态
			const formDatas = ref({  // 表单数据
				uid: '',  // 用户id
				money: '',  // 金额
				quarterMonth: [],  // 归属季度
				season_start: '',  // 季度开始月份
				season_end: '',  // 季度结束月份
				desc: '',  // 事由
			});
			const rules = ref({  // 表单验证必填项
				uid: [
					{ required: true, message: "请选择奖励员工", trigger: "change", },
				],
				money: [
					{ required: true, message: "请输入奖励金额", trigger: "change", },
				],
				quarterMonth: [
					{ required: true, message: "请选择奖励时间", trigger: "change", },
				],
				desc: [
					{ required: true, message: "请输入奖励事由", trigger: "change", },
				],
			})
			/**
			 * 
			 * 打开弹框
			 * 
			 * **/
			const openDialog = () => {
				formDatas.value = {
					uid: '',  // 用户id
					money: '',  // 金额
					quarterMonth: [],  // 归属季度
					season_start: '',  // 季度开始月份
					season_end: '',  // 季度结束月份
					desc: '',  // 事由
				};
				getStaff();
				isShowDialog.value = true;
			}
			/**
			 * 
			 * 获取员工选项数据
			 * 
			 * **/
			const getStaff = () => {
				ctx.$request_.post(ctx.$api_.state.Task.BaseInfo.group_info.url)
					.then((res) => {
						if (res.Code === 200) {
							staffOption.value = res.Data ? res.Data : [];
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: res.Message,
								type: 'warning'
							})
						}
					})
			}
			/**
			 * 
			 * 确定新增按钮
			 * 
			 * **/
			const onConfirmAddBtn = () => {
				if (formRef.value) {
					formRef.value.validate((valid) => {
						if (valid) {
							btnLoading.value = true;
							let params = {
								uid: formDatas.value.uid,
								money: formDatas.value.money,
								season_start: formDatas.value.quarterMonth[0],
								season_end: formDatas.value.quarterMonth[1],
								desc: formDatas.value.desc,
							};
							ctx.$request_.post(ctx.$api_.state.Task.PerforAppraisal.addContribute.url, params)
								.then((res) => {
									if (res.Code === 200) {
										ctx.$notify({
											title: '温馨提示',
											message: "新增成功！",
											type: 'success'
										});
										context.emit('determineChange');
										btnLoading.value = false;
										isShowDialog.value = false;
									} else {
										ctx.$notify({
											title: '温馨提示',
											message: res.Message,
											type: 'warning'
										});
										btnLoading.value = false;
									}
								})
						}
					});
				}
			}

			return {
				isShowDialog,
				openDialog,
				staffOption,
				formDatas,
				getStaff,
				rules,
				formRef,
				onConfirmAddBtn,
				btnLoading,
			}
		}
	})
</script>

<style lang="less">
	#add_contribute {
		.el-select {
			width: 100%;
		}

		.el-form-item__label {
			padding: 0;
		}

		.el-dialog__footer {
			text-align: center;
		}

		.el-range-editor.el-input__inner {
			width: 100%;
		}

		.el-input__inner {
			line-height: 1px !important;
		}
	}
</style>